<template>
  <div class="movtile">
    <Header></Header>
    <div class="top-img"></div>
    <div class="desc">
      <h1>全能视频创作“梦工厂”</h1>

      <p>
        幕童是非凡传媒旗下互联网品牌，主要专注于视频行业全流程服务。以整体视频制作生态为依托，针对中、长视频创作者和机构需求，先后推出多个产品：积幕（创作者一站式资源共享平台）、幕童有课（视频制作线上教学平台）、幕童云阅（视频云端协作工具）、幕童场记（拍摄数据速记工具），矢志为视频从业者提供最专业的服务，打造视频创作者的“梦工厂”。
      </p>
      <p>
        <strong>北京非凡影界文化传媒股份有限公司</strong>
        （证券代码：835431），简称非凡传媒，成立于2010年，业界知名“影视工业+互联网”先行者。凭借强大的技术团队、专业的资源平台以及丰富的制作经验，已参与包括《山河令》《少年的你》《战狼》《误杀》《美人鱼》《七月与安生》《西游记之三打白骨精》《空天猎》《同桌的你》《庆余年》《剑王朝》《猎梦特工》等数百部优秀影视作品的制作。同时，公司拥有独立的研发团队，聚焦于打造以虚拟生产为核心的新视觉艺术创造。
      </p>
    </div>
    <div class="data">
      <div class="data-item" v-for="(item, index) in stats" :key="index">
        <h2>
          {{ item.num }}
          <span v-if="item.unit">{{ item.unit }}</span>
        </h2>
        <p>{{ item.txt }}</p>
      </div>
    </div>

    <div class="movies">
      <div class="awards">
        <div class="img-list">
          <img src=" https://ketang-pub.movtile.com/res/official/works-jinji.png" />
          <img src="https://ketang-pub.movtile.com/res/official/works-jinma.png" />
          <img src="https://ketang-pub.movtile.com/res/official/works-jinxiang.png" />
        </div>
        <p>
          参与制作项目囊括了中国电影金鸡奖、台湾电影金马奖、香港电影金像奖、亚洲电影大奖等多个权威影展奖项
        </p>
      </div>
      <div class="bg">
        <img class="bg-img" src="https://ketang-pub.movtile.com/res/official/works-bg.jpg" />
        <img class="bg-img" src="https://ketang-pub.movtile.com/res/official/works-bg.jpg" />
        <img class="bg-img" src="https://ketang-pub.movtile.com/res/official/works-bg.jpg" />
      </div>
    </div>

    <div class="locations">
      <h2>联系我们</h2>
      <div class="loc">
        <div class="loc-item" v-for="(item, index) in locations" :key="index">
          <iframe width="100%" :src="item.map"></iframe>
          <h3>{{ item.name }}</h3>
          <p>{{ item.address }}</p>
          <p>{{ item.contact }}</p>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
  import Header from '@/components/Header';
  import Footer from '@/components/Footer';

  export default {
    data() {
      return {
        stats: [
          {
            num: '300+',
            txt: '参与影视作品',
          },
          {
            num: '400+',
            txt: '行业合作伙伴',
          },
          {
            num: '28',
            unit: '项',
            txt: '科技专利',
          },
        ],
        locations: [
          {
            name: '北京 BEIJING',
            address: '联系地址：北京市朝阳区半截塔路55号七棵树创意园C3栋',
            contact: '联系电话：010-64358416',
            map: 'https://m.amap.com/navi/?dest=116.519951,39.968896&destName=北京市朝阳区半截塔路55号七棵树创意园C3栋&hideRouteIcon=1&key=0d1dd8806ea5d09128949b5db8551e34',
          },
          {
            name: '长沙 CHANGSHA',
            address: '联系地址：湖南省长沙市开福区御景龙城别墅A区46栋',
            contact: '联系电话：17267279005',
            map: 'https://m.amap.com/navi/?dest=113.037271,28.246477&destName=湖南省长沙市开福区御景龙城A区46栋&hideRouteIcon=1&key=0d1dd8806ea5d09128949b5db8551e34',
          },
        ],
      };
    },
    components: {
      Header,
      Footer,
    },
  };
</script>

<style lang="less" scoped>
  .movitle {
    min-height: 100vh;
    color: #222222;
  }

  .top-img {
    background: url(https://ketang-pub.movtile.com/res/official/top-bg.jpg) no-repeat;
    background-size: cover;
    height: 700px;
  }

  .desc {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 50px;
      font-weight: 800;
      margin-bottom: 84px;
      font-weight: 800;
    }

    p {
      max-width: 1500px;
      margin: 0 20px 50px;
      text-align: left;
      line-height: 1.8;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .data {
    display: flex;
    width: calc(100% - 20px);
    margin: 0 10px 10px;
    justify-content: space-between;

    .data-item {
      width: 33%;
      height: 300px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;

      h2 {
        font-size: 70px;
        margin-bottom: 70px;
        font-family: Knockout;
        span {
          font-size: 16px;
          font-weight: 800;
        }
      }
    }
  }

  .movies {
    position: relative;
    margin-bottom: 80px;

    .bg {
      max-width: 100%;
      display: flex;
      font-size: 0;
      overflow: hidden;
      background: black;
      .bg-img {
        width: 100%;
        animation: moveisBg 120s infinite linear;
      }
    }

    .awards {
      position: absolute;
      width: 100%;
      height: 100%;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .img-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8%;
        width: 35%;
        img {
          width: 20%;
        }
      }

      p {
        flex-shrink: none;
        margin: 0 30px;
        font-weight: 800;
      }
    }
  }

  .locations {
    text-align: center;
    width: 100%;
    margin-bottom: 50px;

    h2 {
      margin-bottom: 80px;
      font-size: 50px;
      color: #222222;
      font-weight: 800;
    }

    .loc {
      width: calc(100% - 20px);
      max-width: 1600px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .loc-item {
        width: 49.5%;
        text-align: left;
        overflow: hidden;
      }
    }

    iframe {
      border: none;
      max-width: 780px;
      height: 300px;
      margin-bottom: -20px;
      transform: translateY(-48px);
    }

    h3 {
      font-size: 34px;
      margin-bottom: 20px;
      font-weight: 800;
    }

    p {
      line-height: 2;
    }
  }

  @media screen and (max-width: 1536px) {
    .top-img {
      height: 480px;
    }
    .movies {
      .bg {
        .bg-img {
          height: 300px;
        }
      }
    }
    .locations {
      .loc {
        max-width: 1200px;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .locations {
      .loc {
        max-width: 800px;
        display: block;
        iframe {
          margin-bottom: -40px;
        }

        .loc-item {
          width: 100%;
          margin-bottom: 20px;
        }

        h3 {
          margin-bottom: 10px;
          font-size: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .top-img {
      height: 120px;
    }

    .desc {
      padding: 50px 0;
      h1 {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }

    .data {
      .data-item {
        h2 {
          font-size: 30px;
          span {
            font-size: 12px;
          }
        }
      }
    }

    .movies {
      margin-bottom: 40px;
      .awards {
        .img-list {
          width: 70%;
        }
      }
    }

    .locations {
      h2 {
        font-size: 30px;
        margin-bottom: 40px;
      }

      .loc {
        display: block;
        iframe {
          margin-bottom: -40px;
        }

        .loc-item {
          width: 100%;
          margin-bottom: 20px;
        }

        h3 {
          margin-bottom: 10px;
          font-size: 24px;
        }
      }
    }
  }

  @keyframes moveisBg {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-100%);
    }
  }
</style>
